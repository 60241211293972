@font-face {
  font-family: 'Bliss2';
  src: url("../fonts/Bliss2-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bliss2';
  src: url("../fonts/Bliss2-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bliss2';
  src: url("../fonts/Bliss2-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bliss2';
  src: url("../fonts/Bliss2-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GEFlow';
  src: url("../fonts/GEFlow-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GEFlow';
  src: url("../fonts/GEFlow-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'one-central';
  src:  url('../fonts/icons/one-central.eot?u7e3pa');
  src:  url('../fonts/icons/one-central.eot?u7e3pa#iefix') format('embedded-opentype'),
    url('../fonts/icons/one-central.ttf?u7e3pa') format('truetype'),
    url('../fonts/icons/one-central.woff?u7e3pa') format('woff'),
    url('../fonts/icons/one-central.svg?u7e3pa#one-central') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="one-central-"], [class*=" one-central-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'one-central' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.one-central-customer-experience:before {
  content: "\e927";
}
.one-central-emissions:before {
  content: "\e91d";
}
.one-central-play:before {
  content: "\e935";
}
.one-central-fortune-500:before {
  content: "\e934";
}
.one-central-minus:before {
  content: "\e900";
}
.one-central-wifi:before {
  content: "\e901";
}
.one-central-user-shield:before {
  content: "\e902";
}
.one-central-triangle:before {
  content: "\e903";
}
.one-central-sustainable-procurement:before {
  content: "\e904";
}
.one-central-support:before {
  content: "\e905";
}
.one-central-search:before {
  content: "\e906";
}
.one-central-resource-preservation:before {
  content: "\e907";
}
.one-central-plus:before {
  content: "\e908";
}
.one-central-phone:before {
  content: "\e909";
}
.one-central-people-chat:before {
  content: "\e90a";
}
.one-central-pedestrian:before {
  content: "\e90b";
}
.one-central-pause:before {
  content: "\e90c";
}
.one-central-party:before {
  content: "\e90d";
}
.one-central-parking:before {
  content: "\e90e";
}
.one-central-online-setup:before {
  content: "\e90f";
}
.one-central-office:before {
  content: "\e910";
}
.one-central-metro:before {
  content: "\e911";
}
.one-central-menu:before {
  content: "\e912";
}
.one-central-map-pin:before {
  content: "\e913";
}
.one-central-mail:before {
  content: "\e914";
}
.one-central-link:before {
  content: "\e915";
}
.one-central-leaf:before {
  content: "\e916";
}
.one-central-layouts:before {
  content: "\e917";
}
.one-central-instagram:before {
  content: "\e918";
}
.one-central-hand-key:before {
  content: "\e919";
}
.one-central-governance:before {
  content: "\e91a";
}
.one-central-external-link:before {
  content: "\e91b";
}
.one-central-employee-wellbeing:before {
  content: "\e91c";
}
.one-central-e-services:before {
  content: "\e91e";
}
.one-central-e-application-support:before {
  content: "\e91f";
}
.one-central-e-application-submission:before {
  content: "\e920";
}
.one-central-dining:before {
  content: "\e921";
}
.one-central-developments:before {
  content: "\e922";
}
.one-central-dashboard:before {
  content: "\e923";
}
.one-central-community:before {
  content: "\e924";
}
.one-central-community-welfare:before {
  content: "\e925";
}
.one-central-close:before {
  content: "\e926";
}
.one-central-clock:before {
  content: "\e928";
}
.one-central-city-pin:before {
  content: "\e929";
}
.one-central-chat:before {
  content: "\e92a";
}
.one-central-certificate:before {
  content: "\e92b";
}
.one-central-card:before {
  content: "\e92c";
}
.one-central-camera:before {
  content: "\e92d";
}
.one-central-atmosphere:before {
  content: "\e92e";
}
.one-central-award:before {
  content: "\e92f";
}
.one-central-arrow-small:before {
  content: "\e930";
}
.one-central-arrow-medium:before {
  content: "\e931";
}
.one-central-arrow-large:before {
  content: "\e932";
}
.one-central-architecture:before {
  content: "\e933";
}